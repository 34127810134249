import React, { useState } from 'react';

import { IModal } from '../../models/modal.model';
import ModalContext from './modal-context';

interface IModalContextProvider {
    children: React.ReactNode;
}

export default function ModalContextProvider({ children }: IModalContextProvider) {
    const [modals, setModals] = useState<IModal[]>([]);

    const showModal = (data: Omit<IModal, 'modalId'>) => {
        const modalId = new Date().getTime();
        setModals([...modals, { modalId, ...data }]);

        return modalId;
    };

    const closeModal = (modalIdToRemove: number) => {
        setModals((prevState) => {
            const modalIndexToRemove = prevState.findIndex(({ modalId }) => {
                return modalId === modalIdToRemove;
            });
            const newState = [...prevState];

            newState.splice(modalIndexToRemove, 1);

            return newState;
        });
    };

    return (
        <ModalContext.Provider value={{ modals, showModal, closeModal }}>
            {children}
        </ModalContext.Provider>
    );
}
