import React, { useState } from 'react';

import SettingsContext from './settings-context';
import { useLocalStorage } from '../../hooks/use-local-storage';
import useHasMounted from '../../hooks/use-has-mounted';

export const SettingsContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const hasMounted = useHasMounted();

    const [isContrastModified, setIsContrastModified] = useLocalStorage<boolean>(
        'isContrastModified',
        false
    );

    const [isKeyboardActivated, setIsKeyboardActivated] = useState<boolean>(false);

    const [fontSizeMultiplier, setFontSizeMultiplier] = useLocalStorage<number>('fontSizeMultiplier', 1);
    const [lineHeightMultiplier, setLineHeightMultiplier] = useLocalStorage<number>('lineHeightMultiplier', 1);
    const [letterSpacingMultiplier, setLetterSpacingMultiplier] = useLocalStorage<number>('letterSpacingMultiplier', 1);
    const [paragraphSpaceMultiplier, setParagraphSpaceMultiplier] = useLocalStorage<number>('paragraphSpaceMultiplier', 1);
    const [wordSpacingMultiplier, setWordSpacingMultiplier] = useLocalStorage<number>('wordSpacingMultiplier', 1);

    return (
        <SettingsContext.Provider
            value={{
                modifiedContrast: hasMounted && isContrastModified,
                activatedKeyboard: isKeyboardActivated,
                fontSizeMultiplier: fontSizeMultiplier,
                lineHeightMultiplier: lineHeightMultiplier,
                letterSpacingMultiplier: letterSpacingMultiplier,
                paragraphSpaceMultiplier: paragraphSpaceMultiplier,
                wordSpacingMultiplier: wordSpacingMultiplier,
                changeContrast: setIsContrastModified,
                toggleKeyboard: setIsKeyboardActivated,
                setFontSizeMultiplier: setFontSizeMultiplier,
                setLineHeightMultiplier: setLineHeightMultiplier,
                setLetterSpacingMultiplier: setLetterSpacingMultiplier,
                setParagraphSpaceMultiplier: setParagraphSpaceMultiplier,
                setWordSpacingMultiplier: setWordSpacingMultiplier,
            }}
        >
            {children}
        </SettingsContext.Provider>
    );
};
