import React, { Suspense, useContext } from 'react';

import ModalContext from './modal-context';
import { modalFactory } from './modal-context.utility';

export default function ModalContextConsumer() {
    const { modals } = useContext(ModalContext);

    if (modals.length === 0) return null;

    return (
        <div data-testid={process.env.NODE_ENV === 'test' ? 'modal-context-consumer' : undefined}>
            {modals.map(({ modalId, modalKey, modalProps }) => {
                const Modal = React.lazy(modalFactory(modalKey));

                return (
                    <Suspense key={modalId} fallback={null}>
                        <Modal modalId={modalId} {...modalProps} />
                    </Suspense>
                );
            })}
        </div>
    );
}
