import React from 'react';

import ISettingsContext from './settings-context.model';

export const initialContext: ISettingsContext = {
    modifiedContrast: false,
    activatedKeyboard: false,
    fontSizeMultiplier: 1,
    lineHeightMultiplier: 1,
    letterSpacingMultiplier: 1,
    paragraphSpaceMultiplier: 1,
    wordSpacingMultiplier: 1,
    changeContrast: () => null,
    toggleKeyboard: () => null,
    setFontSizeMultiplier: () => null,
    setWordSpacingMultiplier: () => null,
    setLetterSpacingMultiplier: () => null,
    setLineHeightMultiplier: () => null,
    setParagraphSpaceMultiplier: () => null,
};

const SettingsContext = React.createContext(initialContext);

export default SettingsContext;
