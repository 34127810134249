import React from 'react';

import IModalContext from './modal-context.model';

export const noContextErrorMessage =
    'First, you have to wrap your root component with Modal Context if you want to use this function!';

const initialContext: IModalContext = {
    modals: [],
    showModal() {
        throw Error(noContextErrorMessage);
    },
    closeModal() {
        throw Error(noContextErrorMessage);
    },
};

const ModalContext = React.createContext(initialContext);

export default ModalContext;
