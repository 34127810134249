import React from 'react';
import { GatsbyBrowser } from 'gatsby';
import { QueryClient, QueryClientProvider } from 'react-query';

import './src/styles/reset.scss';
import './src/styles/variables.scss';
import './src/styles/global.scss';

import { ModalContextProvider } from './src/contexts/modal-context';
import { SettingsContextProvider } from './src/contexts/settings-context/settings-context-provider';

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => {
    const queryClient = new QueryClient();
    return (
        <QueryClientProvider client={queryClient}>
            <ModalContextProvider>
                <SettingsContextProvider>{element}</SettingsContextProvider>
            </ModalContextProvider>
        </QueryClientProvider>
    );
};
